
            @import 'src/styles/mixins.scss';
        
.image-container {
    display: flex;
    @include handleAllBreakpoints('justify-content', 'imageAlign', 'center');
    @include handleAllBreakpoints(
      'align-items',
      'imageVerticalAlign',
      'flex-start'
    );
    height: 100%;
  
    img {
      @include handleAllBreakpoints('width', 'imageWidth', '100%::auto');
      @include handleAllBreakpoints('height', 'imageHeight', '100%::auto');
      @include handleAllBreakpoints('max-width', 'imageMaxWidth');
      @include handleAllBreakpoints('max-height', 'imageMaxHeight', '100%::auto');
      @include handleAllBreakpoints('min-width', 'imageMinWidth', '100%::auto');
      @include handleAllBreakpoints('min-height', 'imageMinHeight', '100%::auto');
      @include handleAllBreakpoints('object-fit', 'imageObjectFit', 'contain');
  
      @include handleAllBreakpoints('border', 'imageBorder', 'none');
      @include handleAllBreakpoints('box-shadow', 'imageShadow', 'none');
      @include handleAllBreakpoints('border-radius', 'imageBorderRadius', '0px');
    }
  }